

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutLoadingV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {}
  show() {
    this.element.classList.remove('elHide')
  }
  showWaitingOnQueueText(text) {
    this.element.querySelector('.elCheckoutLoadingWaitingOnQueue').classList.remove('elHide')
    this.element.querySelector('.elCheckoutLoadingWaitingOnQueue').innerHTML = text
  }
  hideWaitingOnQueueText() {
    this.element.querySelector('.elCheckoutLoadingWaitingOnQueue').classList.add('elHide')
  }
  hide() {
    this.element.classList.add('elHide')
  }



}

registerComponent('CheckoutLoading/V1', CheckoutLoadingV1)
window["CheckoutLoadingV1"] = CheckoutLoadingV1

