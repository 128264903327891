export const renderedPath = (): string => {
  const renderedPage = document.querySelector('meta[name="rendered-page-url"]')?.getAttribute('content')
  return renderedPage ?? window.location.pathname
}

export const renderedHref = (extraPath?: string): string => {
  const url = new URL(window.location.href)
  url.pathname = renderedPath() + (extraPath ?? '')
  return url.toString()
}
