
 import  { computed } from "nanostores"

import "./radio-v1"
import { CheckoutShippingProfileSelectUpdate } from '@yggdrasil/Checkout/V2/CheckoutShippingProfileSelect/V1/../../types'
import { ShippingOption } from '@yggdrasil/Checkout/V2/CheckoutShippingProfileSelect/V1/../../summary'
import { isEventInside } from 'javascript/lander/cf_utils'
import { isEqual } from 'yggdrasil/src/cfLodash'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutShippingProfileSelectV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}






  onSelectOption(option: ShippingOption) {}

  loadingShipping: boolean
  shippingOptions: ShippingOption[]
  shippingOption: ShippingOption

  mount() {
    this.element.addEventListener('click', evt => {
      const shippingProfileCards = this.element.querySelectorAll('.elShippingProfile');
      for (const card of shippingProfileCards) {
        if(isEventInside(evt, card)) {
          evt.preventDefault();
          const key = card.getAttribute('data-shipping-option')
          if (key){
            const option = this.shippingOptions.find(({ description }) => description === key)
            this.onSelectOption(option.value)
          }
          break;
        }
      }
    })
  }

  show() {
    this.element.classList.remove('forceHide')
  }

  hide() {
    this.element.classList.add('forceHide')
  }

  setOption(option) {
    this.shippingOption = option
    const radio = this.shippingOption && this.element.querySelector(`[data-shipping-option="${this.shippingOption.description}"] .elRadioInput`)
    if (radio) {
      radio.checked = true
    }
  }

  isEqualState(newState: CheckoutShippingProfileSelectUpdate) {
    const updatableKeys = ['hasValidShippingAddress', 'loadingShipping', 'summaryHasError', 'shippingOption', 'shippingOptions']
    const currentState = updatableKeys.reduce((acc, key) => {
      acc[key] = this[key]
      return acc
    }, {})
    return isEqual(newState, currentState)
  }

  updateState(data) {
    const newState = {
      hasValidShippingAddress: false,
      loadingShipping: false,
      summaryHasError: false,
      shippingOption: null,
      shippingOptions: [],
      ...data,
    }
    if (this.isEqualState(newState)) return false
    Object.assign(this, newState)
    this.render()
    return true
  }

  setLoading() {
    this.updateState({
      loadingShipping: true,
      hasValidShippingAddress: true,
    })
  }

  setInvalidAddress() {
    this.updateState({
      hasValidShippingAddress: false,
    })
  }

  setSummaryError() {
    this.updateState({
      summaryHasError: true
    })
  }

  setShippingOptions(shippingOptions, shippingOption) {
    const result = this.updateState({
      shippingOption: shippingOption,
      shippingOptions: this.formatShippingOptions(shippingOptions),
      hasValidShippingAddress: true,
    })
    if (result) this.setOption(this.shippingOption)
  }

  formatShippingOptions(shippingOptions) {
    return shippingOptions.map((option) => { 
      const { description, amount: { amount, currency }, amount_formatted } = option
      return ({
        key: JSON.stringify(option),
        value: option,
        description, 
        priceLabel: amount_formatted,
      })
    })
  }

  update ({shippingOptions, ...state}: CheckoutShippingProfileSelectUpdate) {
    Object.assign(this, state)

    if(shippingOptions) {
      this.shippingOptions = shippingOptions?.map((option) => { 
      const { description, amount: { amount, currency }, amount_formatted } = option
        return ({
          key: JSON.stringify(option),
          value: option,
          description, 
          priceLabel: amount_formatted,
        })
      })
    }

    this.render()
    this.setOption(this.shippingOption)
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const title = this.title ?? null ;
const titleEnabled = this.titleEnabled ?? null ;
const otherTexts = this.otherTexts ?? null ;
const shippingOptions = this.shippingOptions ?? [] ;
const shippingOption = this.shippingOption ?? undefined ;
const hasValidShippingAddress = this.hasValidShippingAddress ?? false ;
const loadingShipping = this.loadingShipping ?? false ;
const summaryHasError = this.summaryHasError ?? false ;

      
      let html = '';
      {
        if (titleEnabled) {
          html += `<div class="elCheckoutFormLabelContainer"><div class="elCheckoutFormLabel">${title}</div> <hr class="elCheckoutFormLabelDivider"/></div>`
        }
        html += `<div class="elShippingProfileList" ygg-render="replace">`
        if (summaryHasError) {
          html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><p>Something Unexpected Happened!</p></div></div></div>`
        } else if (hasValidShippingAddress || true != true) {
          if (loadingShipping) {
            html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><div><i class="fa fa-spinner fa-spin"></i></div></div></div></div>`
          } else if (shippingOptions && shippingOptions?.length > 0) {
            const c0 = shippingOptions
            const fl1 = new CF2ForloopDrop(c0.length)
            for (const shippingProfile of c0) {
              const forloop = fl1
              const currentIndex = forloop?.index0;
              const isDefaultValue = currentIndex == 0;
              const hasValue = shippingOption;
              const isSelected = shippingOption == shippingProfile?.key;
              html += `      `
              const isChecked = hasValue && isSelected || isDefaultValue;
              html += `      `
              html += `<div class="elShippingProfileCardWrapper"><div class="elShippingProfile elShippingProfileLayout`
              if (isChecked) {
                html += ` elShippingProfileSelected`
              }
              html += ` elShippingProfileClickable" data-shipping-option="${shippingProfile?.description}"><div class="elShippingProfileLayoutBackground"></div><div class="elShippingProfileSelectInput">`
              if (isChecked) {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="shipping_profile" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              } else {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="shipping_profile"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              }
              html += `</div>  <div class="elShippingProfileCardNameContainer"><div class="elShippingProfileCardInfoName">${shippingProfile?.description}</div></div>    <div class="elShippingProfileCardPriceContainer"><span class="elShippingProfileInfoPrice elShippingProfileFinalPrice">${shippingProfile?.priceLabel}</span></div></div></div>`
              forloop.next();
            }
          } else if (shippingOptions && shippingOptions?.length == 0) {
            html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><div><i class="fa fa-truck"></i></div><p>Shipping is not available for this location.</p></div></div></div>`
          }
        } else {
          html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><div><i class="fa fa-truck"></i></div><p>${otherTexts?.shippingOptionsPlaceholder}</p></div></div></div>`
        }
        html += `</div>`

      }

      this.replaceContent(html) 

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('CheckoutShippingProfileSelect/V1', CheckoutShippingProfileSelectV1)
window["CheckoutShippingProfileSelectV1"] = CheckoutShippingProfileSelectV1

