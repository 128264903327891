import { CF2Component } from 'javascript/lander/runtime'
import { CartData } from 'src/Elements/Cart/types'
import { validateInput } from 'src/Elements/Utils/inputValidator'
import { Contact } from '../types'

export function hasPhysicalProducts(cart: CartData): boolean {
  return cart.items.some(({ product_id, variant_id }) => {
    const product = globalThis.globalResourceData.productsById[product_id]
    const variant = globalThis.globalResourceData.variantsById[variant_id]
    let productType = variant?.product_type
    productType = productType || product?.product_type
    return productType == 'physical'
  })
}

export function setupOrderSummaries(component: CF2Component): void {
  const pageOrderSummaries = document.querySelector('[data-page-element="CheckoutOrderSummary/V1"].elCheckout')
  if (pageOrderSummaries) {
    component.getComponents('CheckoutOrderSummary/V1').forEach((c) => {
      c.element.style.display = 'none'
    })
  }
}

export function scrollToFirstVisibleError(el?: HTMLElement): void {
  // TODO: Shouldn't we first consider the first input with error otherwise it will scroll to the message
  // but the first incorrect field might still be out of the viewport
  const $errorWrapper = el ? $(el) : $('[data-error-container="active"]:visible:first').closest('[data-error-wrapper]')
  const $checkoutFormError = $('.elCheckoutFormErrors:visible:first')
  if ($errorWrapper.length > 0) {
    $('html, body')
      .stop(true, true)
      .animate(
        {
          scrollTop: $errorWrapper.offset().top - 100,
        },
        500
      )
  } else if ($checkoutFormError.length > 0) {
    $('html, body')
      .stop(true, true)
      .animate(
        {
          scrollTop: $checkoutFormError.offset().top - 100,
        },
        500
      )
  }
}
const pageElementContexts = [
  { selector: '[data-page-element="ModalContainer/V1"]', context: 'Modal' },
  { selector: '[data-page-element="ContentNode"]', context: 'ContentNode' },
]
function getCheckoutContext(checkoutElement: HTMLElement): HTMLElement {
  let found
  pageElementContexts.find(({ selector }) => {
    found = checkoutElement.closest(selector)
    return found
  })
  return found
}

export function checkValidCustomFields(checkoutElement: HTMLElement): boolean {
  const contextElement = getCheckoutContext(checkoutElement)
  if (!contextElement) return true

  contextElement.querySelectorAll<HTMLElement>('.elFormItemWrapper:not(.elCheckoutInput)').forEach((el) => {
    el.classList.remove('elInputError', 'elInputWarning', 'elInputValid')
  })

  const formItems = Array.from(
    contextElement.querySelectorAll<HTMLElement>('.elFormItem.required1:not(.elCheckoutInput .elFormItem.required1)')
  ).filter((input) => !!input.getAttribute('data-custom-type') || input.getAttribute('type') == 'checkbox')

  const results = []
  formItems.forEach((input) => {
    const result = validateInput(input)
    results.push(result)
    let thisInput = $(input)
    const parent = thisInput.parents('.elFormItemWrapper')
    thisInput =
      parent.length && parent.find('.inputHolder, .borderHolder, .elCheckbox').length
        ? parent.find('.inputHolder, .borderHolder, .elCheckbox')
        : thisInput

    if (result) {
      thisInput.css('border-color', '#4a8920')
      thisInput.css('border-width', '3px')
    } else {
      thisInput.css('border-color', '#B91517')
      thisInput.css('border-width', '3px')
    }
  })

  return results.every((r) => !!r)
}

export function buildCustomFields(): Record<string, string> {
  const fields = {}
  document
    .querySelectorAll<HTMLInputElement>(
      "[type='custom_type'], select[data-custom-type], [type='custom_type'] [type='checkbox']"
    )
    .forEach((input) => {
      fields[input.name] = input.type === 'checkbox' ? input.checked : input.value
    })
  return fields
}

export function collectLeads(contactDetails: Contact): void {
  globalThis.CFDispatchEvent(globalThis.CFEvents.FORM_SUBMITTED, { contact: contactDetails })
  fetch('/user_pages/api/contacts', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(contactDetails),
  }).then((response) => {
    if (response.ok) {
      globalThis.CFDispatchEvent(globalThis.CFEvents.FORM_SUBMITTED_OK, { contact: contactDetails })
    }
  })
}
