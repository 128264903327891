
import "./checkbox-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutTosV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

onAcceptedChange(accepted: boolean) {}

  get accepted() {
    return this.element.querySelector('input').checked
  }
  
  mount() { 
    const input = this.element.querySelector('input')

    input.addEventListener('change', (event) => {
      this.onAcceptedChange(!!event?.target?.checked)
    })

  }



}

registerComponent('CheckoutTos/V1', CheckoutTosV1)
window["CheckoutTosV1"] = CheckoutTosV1

