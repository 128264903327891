import { renderedPath } from '../Utils/checkout-path'
import Fetcher, { FetcherRequestOptions } from 'javascript/lander/utils/fetcher'

export function makeCurrentPageFetcher<T>(options?: { headers?: HeadersInit } & FetcherRequestOptions): Fetcher<T> {
  const { headers, ...requestOptions } = options ?? {}
  const fetcher = new Fetcher<T>({
    debug: true,
    pathPrefix: window.location.origin + renderedPath(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(headers ?? {}),
    },
    requestOptions: {
      retries: 3,
      timeoutMS: 30000,
      timeoutAfterRetrial: 1000,
      convertThrowToErrorResponse: true,
      onlyOneInflightRequest: true,
      ...requestOptions,
    },
  })
  return fetcher
}
