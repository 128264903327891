

import { CheckoutSubmit } from "@yggdrasil/Checkout/V2/Services/checkout-submit"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutApplePayButtonV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



    mount() {
      this.applePayMounted = nanostores.atom(false)

      const checkoutElement = this.element.closest('.elCheckout')
      if (!!this.getApplePayPreview()) {
        this.getApplePayPreview().addEventListener('click', (evt) => {
          evt.preventDefault()
          CheckoutSubmit.checkSubmitShowErrors({ onlyFields: new Set(['products']) })
          CheckoutSubmit.checkSubmit(checkoutElement)
        })

        new MutationObserver((mutations) => {
          for (const mutation of mutations) {
            for(const addedNode of mutation.addedNodes) {
              if(addedNode.id == "rebilly-apple-pay-button") {
                this.applePayMounted.set(true)
              }
            }
            for(const removedNode of mutation.removedNodes) {
              if(removedNode.id == "rebilly-apple-pay-button") {
                this.applePayMounted.set(false)
              }
            }
          }
        }).observe(this.element.querySelector(".apple-pay-button"), {
          subtree: true,
          childList: true,
        });

        const computedState = nanostores.computed([
          Checkout.computed.isDigitalWalletReadyToStart,
          this.applePayMounted,
          Checkout.store.isUpdatingRebilly
        ], (isDigitalWalletReadyToStart, isMounted, isUpdatingRebilly) => {
          if (isUpdatingRebilly) {
            return 'loading'
          } else if (!isMounted || !isDigitalWalletReadyToStart) {
            return 'preview'
          } else {
            return 'ready'
          }
        })
        computedState.subscribe((state) => {
          this.getApplePayWrapper() && (this.getApplePayWrapper().dataset.state = state)
        })
        const computedShowApplePay = nanostores.computed([
          Checkout.store.payment.id,
          Checkout.store.payment.type,
        ], (paymentId, paymentType)=> {
          return !paymentId && paymentType == 'apple-pay' && this.mode != 'upgradeDowngrade'
        })
        computedShowApplePay.subscribe((showApplePay) => {
          this.element.closest('.submit-button-wrapper')?.setAttribute('data-show-apple-pay', showApplePay)
        })
      }
    }

    getApplePayWrapper() {
      return this.element.querySelector('.apple-pay-button-wrapper')
    }

    getApplePayPreview() {
      return this.element.querySelector('.apple-pay-button-preview')
    }



}

registerComponent('CheckoutApplePayButton/V1', CheckoutApplePayButtonV1)
window["CheckoutApplePayButtonV1"] = CheckoutApplePayButtonV1

