

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutSubmitNotificationV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {}

  setStatus(text, subtext, extratext = "") {
    if (text) {
      this.element.querySelector('.mainText').classList.remove('elHide')
      this.element.querySelector('.mainText').innerHTML = text
    } else {
      this.element.querySelector('.mainText').classList.add('elHide')
    }
    if (subtext) {
      this.element.querySelector('.subText').classList.remove('elHide')
      this.element.querySelector('.subText').innerHTML = subtext
    } else {
      this.element.querySelector('.subText').classList.add('elHide')
    }
    if (extratext) {
      this.element.querySelector('.extraText').classList.remove('elHide')
      this.element.querySelector('.extraText').innerHTML = extratext
    } else {
      this.element.querySelector('.extraText').classList.add('elHide')
    }
  }



}

registerComponent('CheckoutSubmitNotification/V1', CheckoutSubmitNotificationV1)
window["CheckoutSubmitNotificationV1"] = CheckoutSubmitNotificationV1

