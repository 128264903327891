
import "./checkbox-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutProductCardBumpV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

skipMountOnRender = true

  mount() {
  }
  
  render() {
    const selected_price = this.selected_price ?? {};
    const showCompareAtPrice = this.element.getAttribute('data-param-showCompareAtPrice') === "true";
    const checkBox = this.getComponent('Checkbox/V1');
    
    checkBox.setChecked(this.isChecked);
    const arrow = this.element.querySelector('.elProductBumpHeadlineArrow');
    const modernButtonText = this.element.querySelector('.elProductCardModernButton > span');
    if(this.isChecked) {
      this.element.classList.add('elProductSelected');
      if (arrow) arrow.classList.add('elHide');
      if (modernButtonText) modernButtonText.innerText = '- Unselect';
    } else {
      this.element.classList.remove('elProductSelected');
      if (arrow) arrow.classList.remove('elHide');
      if (modernButtonText) modernButtonText.innerText = '+ Add';
    }

    const finalPriceEl = this.element.querySelector('.elProductCardFinalPrice')
    if (finalPriceEl) finalPriceEl.innerText = selected_price.name;

    if(showCompareAtPrice && selected_price.compare_at_amount) {
      const anchorPriceEl = this.element.querySelector('.elProductCardAnchorPrice')
      if (anchorPriceEl) anchorPriceEl.innerText = selected_price.compare_at_amount;
    }

    if(this.selectType === 'quantity') {
      const quantityInput = this.element.querySelector('.elProductCardShortInput');
      if(quantityInput) quantityInput.value = this.quantity;
    }
    
  }



}

registerComponent('CheckoutProductCardBump/V2', CheckoutProductCardBumpV2)
window["CheckoutProductCardBumpV2"] = CheckoutProductCardBumpV2

