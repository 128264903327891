/* eslint-disable camelcase */
export function get_available_variant (product: any): any {
  if (product?.sorted_property_values?.length) {
    const allOrderedVariantCombinations = product
      .sorted_property_values
      .map(p => p.value_ids).reduce((a, b) => a.reduce((r, v) => r.concat(b.map(w => [].concat(v, w))), []))
    const availableCombination = allOrderedVariantCombinations?.find((combination) => {
      const availableCombinationIdentifier = Array.isArray(combination) ? combination.join(',') : combination
      return product.property_values_variant_mapping[availableCombinationIdentifier]
    })
    const variantId = product.property_values_variant_mapping[availableCombination]
    return product.variants.find((v) => v.id === String(variantId)) ?? product.variants?.[0]
  }
  return product?.variants?.[0]
}
